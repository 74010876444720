import React from 'react';
// import "../css/Header.css";
import { HiOutlineSearch } from "react-icons/hi";
import { connect } from 'react-redux';
import { store } from "../store"
import { withRouter } from './withRouter';

const Header = ({ subHeading, heading, search, onSearch, getOnSearch }) => {

    const user = store.getState().user
    const saloon = store.getState().saloon


    // const path = window.location.pathname;
    // const screenName = path.split("/");
    // const result = screenName[1].toLocaleUpperCase();

    return (
        <div className='main-div-header'>
            <div className='header-flex'>
                <div className='header-top'>
                    <h2 className='heading'>{heading}</h2>
                    <p className='header-para'>{subHeading}</p>

                    {/* {
                        window.location.pathname === "/Bookings" ?
                            <p className='header-para'>Manage your booking details.</p> :
                            null
                    }
                    {
                        window.location.pathname === "/Listings" ?
                            <p className='header-para'>Manage all details about listings.</p> :
                            null
                    } */}
                </div>
                {
                    search &&
                    <div className='search-header'>
                        <div className='search-icon'><HiOutlineSearch /></div>
                        <input value={onSearch} onChange={(e) => getOnSearch(e.target.value)} type="text" placeholder='Search everything' />
                    </div>
                }
                <div className='profile'>
                    <div className='profile-img'>
                        <img style={{ width: "50px", height: "50px", objectFit: "cover" }} src={require("../assets/dummyavatar.png")} alt="" />

                    </div>
                    <div className='profile-text'>
                        <p className='person-name'>{user?.email}</p>
                        <p className='person-admin'>Saloon account</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,
        saloon: state.saloon,

    }
}



export default connect(mapStatetoProps, null)(withRouter(Header));
