import logo from './logo.svg';
import './App.css';
import "./css/Dashboard.css"
import Register from './screens/Register';
import Login from './screens/Login';
import Profile from './screens/Profile';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { Routes, Route } from "react-router-dom"
import Listings from './screens/Listings';
import { withRouter } from './components/withRouter';
import Bookings from './screens/Bookings';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateStore from './screens/CreateStore';
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/ResetPassword';
import NewPassword from './screens/NewPassword';
import { connect } from 'react-redux';
import React from 'react';
import Transaction from './screens/Transaction';
import Chat from "./screens/chat"


function App(props) {
  return (
    //     <div style={{ display: "flex" }}>

    //       {
    //         window?.location?.pathname === "/" || window?.location?.pathname === "/register" || window?.location?.pathname === "/createstore" || window?.location?.pathname === "/forgotpassword" || window?.location?.pathname === "/resetpassword" || window?.location?.pathname === "/newpassword" ?
    //           null
    //           :
    //           <div style={{ width: "20%", minHeight: "100vh", backgroundColor: "#FCFCFF" }}>
    //             <Sidebar />
    //           </div>
    //       }


    //       <div style={{ width: "100%" }}>
    //         {
    //           window?.location?.pathname === "/" || window?.location?.pathname === "/register" || window?.location?.pathname === "/createstore" || window?.location?.pathname === "/forgotpassword" || window?.location?.pathname === "/resetpassword" || window?.location?.pathname === "/newpassword" ?
    //             null
    //             :
    //             <Header />
    //         }
    //         <ToastContainer />
    //         <Routes>
    //           <Route path="/" element={<Login />} />
    //           <Route path="/Profile" element={<Profile />} />
    //           <Route path="/Listings" element={<Listings />} />
    //           <Route path="/Bookings" element={<Bookings />} />
    //           <Route path="/register" element={<Register />} />
    //           <Route path="/createstore" element={<CreateStore />} />
    //           <Route path="/forgotpassword" element={<ForgotPassword />} />
    //           <Route path="/resetpassword" element={<ResetPassword />} />
    //           <Route path="/newpassword" element={<NewPassword />} />
    //           {
    //             console.log(window.location.pathname, "sacsdcsc")
    //           }
    //         </Routes>
    //       </div>

    //     </div>
    //   );
    // }
    <div>

      <div style={{ display: "flex" }}>
        {
          props?.user ?
            <div style={{ width: "20%", minHeight: "100vh", backgroundColor: "#FCFCFF" }}>
              <Sidebar />

            </div> :
            null
        }


        <div style={{ width: "100%" }}>
          <React.Fragment>
            <ToastContainer />
            <Routes>
              {
                !props.user ?
                  <React.Fragment>
                    <Route path="/" element={<Login />} />
                    <Route path="/forgotpassword" element={<ForgotPassword />} />
                    <Route path="/resetpassword" element={<ResetPassword />} />
                    <Route path="/newpassword" element={<NewPassword />} />
                  </React.Fragment>
                  :
                  <React.Fragment>


                    <Route path="/" element={<Profile />} />
                    <Route path="/Listings" element={<Listings />} />
                    <Route path="/Bookings" element={<Bookings />} />
                    <Route path="/createstore" element={<CreateStore />} />
                    <Route path="/chat" element={<Chat />} />

                    <Route path="/transaction" element={<Transaction />} />
                  </React.Fragment>
              }


            </Routes>
          </React.Fragment>
        </div>
      </div>
    </div>
  )
}

const mapStatetoProps = (state) => {
  return {
    user: state.user,

    logged: state.logged
  }
}
export default connect(mapStatetoProps, null)(withRouter(App));
