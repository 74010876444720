// import React from 'react';

// import {
//     Button,
//     Dialog,
//     DialogTitle,
//     DialogContent,
//     DialogActions
// } from '@material-ui/core';


// const Modal = ({ open, onClick, haeding }) => {
//     return (
//         <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open}>
//             <DialogTitle id="dialog-title">{haeding}</DialogTitle>
//             <DialogContent>
//                 <div style={{ minHeight: '400px', width: '400px' }}>
//                     <div>
//                         <p style={{ fontWeight: 500, fontSize: "18px" }}>List Title</p>
//                         <input className='modal-input' placeholder='Enter Title'></input>
//                     </div>
//                     <div>
//                         <p style={{ fontWeight: 500, fontSize: "18px" }}>Price</p>
//                         <input className='modal-input' placeholder='Enter Price'></input>
//                     </div>
//                     <div>
//                         <p style={{ fontWeight: 500, fontSize: "18px" }}>Discounts</p>
//                         <input className='modal-input' placeholder='Enter Discount'></input>
//                     </div>
//                     <div>
//                         <p style={{ fontWeight: 500, fontSize: "18px" }}>Festivals</p>
//                         <input className='modal-input' placeholder='Enter Festival'></input>
//                     </div>
//                     <div>
//                         <p style={{ fontWeight: 500, fontSize: "18px" }}>Category</p>
//                         <input className='modal-input' placeholder='Enter Category'></input>
//                     </div>
//                     <div>
//                         <p style={{ fontWeight: 500, fontSize: "18px" }}>Select Image</p>
//                         <input type="file" id="files" name="files" multiple="multiple"></input>
//                     </div>
//                 </div>
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={onClick}>Close</Button>
//             </DialogActions>
//         </Dialog>
//     )
// }

// export default Modal;
import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
// import "../css/Modal.css";
import DropDown from './DropDown';
// import "../css/DropDown.css";
import { TiDelete } from "react-icons/ti";
import { RiImageAddFill } from "react-icons/ri";

const EditModal = ({ open, onClick }) => {
    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open} >
            <div className='close-btn' onClick={onClick}><TiDelete /></div>
            <DialogTitle id="dialog-title">
                <h2>{window.location.pathname.split("/")[1].toUpperCase()}</h2>
                <div className='line'></div>
            </DialogTitle>
            <DialogContent>
                <div className="modal-main" >
                    <label htmlFor='username'>Name</label>
                    <input type="text" id='username' placeholder='Enter Name' />
                    <label htmlFor='account-type'>Message</label>
                    <input type="text" id='account-type' placeholder='Enter Message' />


                </div>
                <div className='drop'>
                    <label>Category</label>
                    <DropDown
                        options={[
                            { label: 'Approved', value: 'Approved' },
                            { label: 'Pending', value: 'Pending' },
                            { label: 'Disabled', value: 'Disabled' },
                        ]}
                    />
                </div>
                <div className='image-div'>
                    <label htmlFor='account-type'>Select Image</label>
                    <div className='img-position'>
                        <RiImageAddFill size={70} color={"grey"} />
                    </div>
                    <input style={{ opacity: "0" }} type="file" id='account-type' />
                </div>
            </DialogContent>
            <div className='but-flex'>
                <div className='cancel'>
                    <button onClick={onClick}>Cancel</button>
                </div>
                <div className='save-changes'>
                    <button onClick={onClick}>Save Changes</button>
                </div>
            </div>
        </Dialog>
    )
}

export default EditModal;