import React, { Component } from "react";

import hermoso from "../assets/hermoso.png"
import { withRouter } from "../components/withRouter";
import { login } from "../Path/Path";
import { toast } from "react-toastify";
import { CircularProgress } from '@mui/material';
import jwt_decode from "jwt-decode";

import { connect } from "react-redux";
import store from "../store";
import action from "../store/action";
class CreateStore extends Component {
    state = {
        email: "",
        password: "",
        loading: "",
    }

    render() {

        return (
            <div>

                <div className="container-fluid p-0 background-pattern">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                            <div className='Login-center'>
                                <div style={{ width: "180px", height: "75px" }}>
                                    <img width="100%" height="100%" style={{ objectFit: "contain" }} src={hermoso}></img>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='login-main'>Create Store</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Name</p>
                                </div>
                                <input value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className='register-input' placeholder='Email Name'></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Address</p>
                                </div>
                                <input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className='register-input' placeholder='Enter Address'></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>About</p>
                                </div>
                                <input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className='register-input' placeholder='Enter About'></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <div>
                                            <input type="checkbox"></input>
                                        </div>
                                        <div style={{ width: "100px" }}>
                                            <p style={{ color: "grey", marginLeft: "3%", marginTop: "0", marginBottom: "4px" }}>keep me logged in</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{ color: "grey" }}>Forgot Password?</p>
                                    </div>
                                </div>
                                <button onClick={() => this.loginHandler()} loading={this.state.loading} className='signup-btn'>
                                    {
                                        this.state.loading ?
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CircularProgress size={15} style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                            </div>

                                            :
                                            <p className='signup-text'>Sign in</p>
                                    }
                                </button>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%", marginBottom: "2%" }}>
                                    <div className="line-grey">
                                    </div>
                                    <div><p style={{ color: "grey", margin: "0", fontSize: "20px" }}>or</p></div>
                                    <div className="line-grey">
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        <p style={{ color: "black", }}>Not a member yet? </p>
                                    </div>
                                    <div >
                                        <p onClick={() => this.props.history("/register")} style={{ color: "#2A5555", fontWeight: 500, cursor: "pointer", marginLeft: "5px" }}>Sign up</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="design-bottom">


                    </div> */}

                </div>

            </div >
        )
    }
}


export default CreateStore