
import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
// import "../css/Modal.css";
import DropDown from './DropDown';
// import "../css/DropDown.css";
import { TiDelete } from "react-icons/ti";
import { RiImageAddFill } from "react-icons/ri";
import NewPickerComp from './NewPickerComp';
import NewPickerCompForModal from './NewPickerCompForModal';
import getImageURL from '../Config/UploadImageFunction';
import { async } from '@firebase/util';
import { toast } from "react-toastify";

const ModalForEdit = ({ open, onClick, name, getName, description, getDescription, price, getPrice, discounts, getDiscounts, mallDrop, getMallId, mallID, onUpdate, getUploadFiles, selectedImages, images }) => {
    const [galleryImage, setGalleryImage] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [galleryUploadFiles, setGalleryUploadFiles] = useState([]);
    const updateHandlerGallery = async () => {
        // setLoading(true)
        const promises = galleryUploadFiles?.map(f => getImageURL(f, null,))
        const links = await Promise.all(promises)
        console.log(links, "links....sheikh");
        // this.setState({ gallery: links })
        setGallery(links)
        return links
        // setLoading(false)

    }

    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open} >
            <div className='close-btn' onClick={onClick}><TiDelete /></div>
            <DialogTitle id="dialog-title">
                <h2>Edit Listing</h2>
                <div className='line'></div>
            </DialogTitle>
            <DialogContent>
                <div className="modal-main" >
                    <form>
                        <label htmlFor='username'>Service Name</label>
                        <input type="text" id='username' placeholder='Service Name' value={name} onChange={(e) => getName(e.target.value)} required />
                        <label htmlFor='username'>Description</label>
                        <input type="text" id='description' placeholder='Description ' value={description} onChange={(e) => getDescription(e.target.value)} />
                        <label htmlFor='account-type'>Price</label>
                        <input type="text" id='account-type' placeholder='Enter Price' value={price} onChange={(e) => getPrice(e.target.value)} />
                        <label htmlFor='account-type'>Discounts</label>
                        <input type="text" id='account-type' placeholder='Enter Discounts' value={discounts} onChange={(e) => getDiscounts(e.target.value)} />
                    </form>



                </div>
                <div className='drop'>
                    <label>Category</label>
                    <DropDown

                        options={mallDrop}
                        onChange={e => getMallId(e)}
                        value={mallID?.categoryName}
                    />


                </div>
                <div className='image-div'>
                    {/* <label htmlFor='account-type'>Select Image</label> */}
                    <p style={{ fontSize: "14px", fontWeight: 500 }}>Select Image</p>
                    {/* <div className='img-position'>
                        <RiImageAddFill size={70} color={"grey"} />
                    </div>
                    <input style={{ opacity: "0" }} type="file" id='account-type' /> */}
                    <div style={{ width: "100%" }}>
                        <NewPickerCompForModal images={images} getUploadFiles={(e) => getUploadFiles(e)} selectedImages={e => selectedImages(e)} multiple={true} />
                    </div>
                </div>
            </DialogContent>
            <div className='but-flex'>
                <div className='cancel'>
                    <button onClick={onClick}>Cancel</button>
                </div>
                <div className='save-changes'>
                    <button onClick={() => onUpdate()}>Save Changes</button>
                </div>
            </div>
        </Dialog>
    )
}

export default ModalForEdit;