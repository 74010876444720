import { LOGOUT, SET_RECIPIENTS, SET_SALOON, SET_USER, } from "./actionTypes";



const setUsers = (payload) => ({
    type: SET_USER,
    payload,
});

const logout = () => ({
    type: LOGOUT,

});
const setSaloon = (payload) => ({
    type: SET_SALOON,
    payload,
});

const setRecipients = payload => ({ type: SET_RECIPIENTS, payload, });

export default {
    setUsers,
    logout,
    setSaloon,
    setRecipients

};
