import React, { Component } from "react";

import hermoso from "../assets/hermoso.png"
import { withRouter } from "../components/withRouter";
import { login, verifyCodeForget } from "../Path/Path";
import { toast } from "react-toastify";
import { CircularProgress } from '@mui/material';
import jwt_decode from "jwt-decode";

import { connect } from "react-redux";
import store from "../store";
import action from "../store/action";
class ForgotPassword extends Component {
    state = {
        email: "",

        loading: "",
    }
    loginHandler = async () => {
        this.setState({ loading: true })
        if (this.state.email === "") {
            toast.warn("Enter Email")

        }

        else {

            let params = {
                email: this.state.email,
                type: "service provider"
            }
            await verifyCodeForget(params)
                .then((res) => {
                    if (res?.success) {
                        this.props.history("/resetpassword", { state: { code: res?.code, email: this.state.email } })
                    } else {
                        toast.warn(res?.message)
                    }
                    console.log(res, "ressssssssssssssss")

                }).catch((err) => {
                    toast.warn(err.message)

                })

        }
        // this.props.history("/")
        this.setState({ loading: false })
    }
    render() {

        return (
            <div>

                <div className="container-fluid p-0 background-pattern">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                            <div className='Login-center'>
                                <div style={{ width: "180px", height: "75px" }}>
                                    <img width="100%" height="100%" style={{ objectFit: "contain" }} src={hermoso}></img>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='login-main'>Forgot Password?</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p style={{ color: "grey", fontSize: "15px" }}>Enter the email address you used when you joined and we will send you instructions to reset your password</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Email</p>
                                </div>
                                <input value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className='register-input' placeholder='Email Address'></input>



                                <button onClick={() => this.loginHandler()} loading={this.state.loading} className='signup-btn'>
                                    {
                                        this.state.loading ?
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CircularProgress size={15} style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                            </div>

                                            :
                                            <p className='signup-text'>Send reset instructions</p>
                                    }
                                </button>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%", marginBottom: "2%" }}>
                                    <div className="line-grey">
                                    </div>
                                    <div><p style={{ color: "grey", margin: "0", fontSize: "20px" }}>or</p></div>
                                    <div className="line-grey">
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        <p style={{ color: "black", }}>Return to </p>
                                    </div>
                                    <div >
                                        <p onClick={() => this.props.history("/")} style={{ color: "#2A5555", fontWeight: 500, cursor: "pointer", marginLeft: "5px" }}>Sign in</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="design-bottom">


                    </div> */}

                </div>

            </div >
        )
    }
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,

    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(ForgotPassword));