
import React from "react";
import { LOGOUT, SET_RECIPIENTS, SET_SALOON, SET_USER, } from "./actionTypes";

const initialState = {
    user: null,
    logged: false,
    saloon: null,
    recipients: []
};

export default (state = initialState, action) => {

    const { type, payload } = action

    switch (type) {

        case SET_USER: {
            return {
                ...state,
                user: payload,
                logged: true
            };

        }
        case LOGOUT: {
            return {
                ...state,
                user: null,
                logged: false,
                saloon: null,
            };

        }

        case SET_SALOON: {
            return {
                ...state,
                saloon: payload,

            };
        }
        case SET_RECIPIENTS: {
            return {
                ...state,
                recipients: payload,

            };
        }

        default:
            return state;
    }
};
