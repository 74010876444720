import React, { Component } from "react";
import salon from "../assets/salon.png";
import room from "../assets/room.png";
import { BsEye } from "react-icons/bs"
import { FaRegEdit } from "react-icons/fa"
import { RiDeleteBinLine } from "react-icons/ri"
import { withRouter } from "../components/withRouter";
import Modal from "../components/Modal";
import PickerSelect from "../components/PickerSelect";
import EditModal from "../components/EditModal";
import swal from "sweetalert";
import AlertModal from "../components/AlertModal";
import Carousel from 'react-bootstrap/Carousel';
import { addService, deletesaloon, deleteService, deleteservice, editService, getAllSaloon, getAllServices, getCategories } from "../Path/Path";
import { connect } from "react-redux";
import action from "../store/action";
import Header from "../components/Header";
import getImageURL from "../Config/UploadImageFunction";
import { toast } from "react-toastify";
import ModalForEdit from "../components/ModalForEdit";
import ModalForView from "../components/ModalForView";
import Loader from "../components/Loader";

class Listings extends Component {
    state = {
        modalopen: false,
        location: "",
        selectedLocation: null,
        editmodalopen: false,
        visible: false,
        data: [],
        allLocation: [],
        newLocations: [],
        filterData: [],
        allMalls: [],
        mallDrop: [],
        mallID: "",
        name: "",
        description: "",
        discounts: "",
        price: "",
        dscounts: "",
        listing: [],
        listingImage: [],
        listingImageNew: [],
        listingUploadFiles: [],
        visibleEdit: false,
        editData: null,
        visibleView: false,
        loading: false,
        filterBookingData: [],

        onSearch: "",
    }
    async componentDidMount() {
        await this.getAllServicesByStoreID()
        let res2 = await getCategories()
        this.getAllMallFunc()

        if (res2?.length > 0) {
            let arr = res2.map(i => i?.categoryName)
            console.log(res2, "new aaaaaa")
            this.setState({ allLocation: arr, newLocations: res2, })

        }
        // const LOCATIONS = [
        //     "New York City, NY",
        //     "Chicago, IL",
        //     "Atlanta, GA",
        //     "Miami, FL",
        // ]

    }
    deleteHandler = async (item) => {
        this.setState({ loading: true })
        swal({
            title: "Alert?",
            text: "Are you sure to delete",
            icon: "warning",
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    let res = await deleteService(item)
                    if (res?.success) {
                        swal("Deleted!", res?.message, "success");
                        await this.getAllServicesByStoreID()
                    }

                }


            })
        this.setState({ loading: false })

    }
    getAllServicesByStoreID = async () => {
        this.setState({ loading: true })
        if (this.props.saloon?._id); {

            let res = await getAllServices(this.props.saloon._id)
            if (res) {
                this.setState({ data: res, filterData: res, filterBookingData: res })
                this.setState({ loading: false })
            }
        }
        this.setState({ loading: false })
    }
    getLocation = (e) => {
        let res = this.state.newLocations.find(f => f?.categoryName === e)
        console.log(this.state.newLocations, "ressss")

        this.setState({ location: res?.categoryName, selectedLocation: res })
        let filterData = this.state.filterData.filter(i => i.categoryID?._id === res?._id)
        this.setState({ filterBookingData: filterData })
    }
    getAllMallFunc = async () => {

        const malls = await getCategories()
        if (malls.length > 0) {
            let newArr = []
            for (let i = 0; i < malls.length; i++) {
                let obj = {
                    label: malls[i].categoryName,
                    value: malls[i].categoryName
                }
                newArr.push(obj)

            }
            newArr.unshift({ label: "Select Category", value: "Select Category" })
            this.setState({ mallDrop: newArr })
            this.setState({ allMalls: malls })
            //   setMallDrop(newArr)
            //   setAllMalls(malls)
        }



    }
    getMallId = (e) => {
        let mall = this.state.allMalls.find(f => f.categoryName === e.target.value)
        this.setState({ mallID: mall })
        // setMallID(mall)

    }
    updateHandlerListingImage = async () => {
        // setLoading(true)
        const promises = this.state.listingUploadFiles?.map(f => getImageURL(f, null,))
        const links = await Promise.all(promises)
        console.log(links, "links....sheikh");
        return links
        // setLoading(false)

    }
    addListings = async () => {
        this.setState({ loading: true })
        if (this.state.name === '') {
            toast.warn('Please enter listing name')
        }
        else if (this.state.description === '') {
            toast.warn('Please enter description')
        }
        else if (this.state.price === '') {
            toast.warn('Please enter price')
        }
        else if (this.state.price === '') {
            toast.warn('Please enter price')
        }
        else if (this.state.listingUploadFiles?.length === 0) {
            toast.warn('Please select images')
        }
        else if (this.state.mallID === '' || this.state.mallID === 'Select Category') {
            toast.warn('Please select category')
        }
        else {
            let listImagesLinks
            if (this.state.listingUploadFiles) {
                listImagesLinks = await this.updateHandlerListingImage()
            }
            let body = {
                name: this.state.name,
                description: this.state.description,
                price: this.state.price,
                storeID: this.props.saloon?._id,
                images: listImagesLinks,
                categoryID: this.state.mallID?._id,
                isActive: true
            }
            await addService(body)
                .then(async (res) => {
                    console.log(res, "ressssssssssssssss")
                    if (res?.success) {
                        await this.getAllServicesByStoreID()
                        toast.success(res?.message)
                        this.setState({ visible: false, name: '', price: '', description: '', discounts: '', mallID: '' })
                        this.setState({ loading: false })
                    }
                    else {
                        toast.warn(res?.message)
                        this.setState({ loading: false })
                    }
                }).catch((err) => {
                    console.log('err: ', err);
                    toast.warn(err?.message)
                    this.setState({ loading: false })
                })
        }

        this.setState({ loading: false })
    }
    editHandler = async () => {
        this.setState({ loading: true })
        if (this.state.name === '') {
            toast.warn('Please enter listing name')
        }
        else if (this.state.description === '') {
            toast.warn('Please enter description')
        }
        else if (this.state.price === '') {
            toast.warn('Please enter price')
        }
        else if (this.state.price === '') {
            toast.warn('Please enter price')
        }
        else if (this.state.mallID === '' || this.state.mallID === 'Select Category') {
            toast.warn('Please select category')
        }
        else {
            let listImagesLinks
            if (this.state.listingUploadFiles.length > 0) {
                listImagesLinks = await this.updateHandlerListingImage()
            }
            let body = {
                name: this.state.name,
                description: this.state.description,
                price: this.state.price,
                storeID: this.props.saloon?._id,
                images: this.state.listingUploadFiles.length > 0 ? listImagesLinks : this.state.editData.images,
                categoryID: this.state.mallID?._id,

            }
            await editService(this.state.editData._id, body)
                .then(async (res) => {
                    console.log(res, "ressssssssssssssss")
                    if (res?.success) {
                        await this.getAllServicesByStoreID()
                        toast.success(res?.message)
                        this.setState({ visibleEdit: false, name: '', price: '', description: '', discounts: '', mallID: '', listingImage: "", listingUploadFiles: "", editData: null })
                        this.setState({ loading: false })
                    }
                    else {
                        toast.warn(res?.message)
                        this.setState({ loading: false })
                    }
                }).catch((err) => {
                    console.log('err: ', err);
                    toast.warn(err?.message)
                    this.setState({ loading: false })
                })
        }
        this.setState({ loading: false })

    }
    setEditValues = (e) => {

        this.setState({ editData: e, visibleEdit: true, name: e?.name, price: e?.price, description: e?.description, discounts: e?.discounts, mallID: e?.categoryID, listingImage: e?.images })
    }
    setEditValues2 = (e) => {

        this.setState({ editData: e, visibleView: true, name: e?.name, price: e?.price, description: e?.description, discounts: e?.discounts, mallID: e?.categoryID, listingImage: e?.images })
    }
    searchHandler = (e) => {
        this.setState({ onSearch: e }, () => {
            if (!this.state.onSearch) {
                this.setState({ filterBookingData: this.state.data })
                return
            }
            const res = this.state.data.filter(i => i.name.toLowerCase().includes(this.state.onSearch.toLowerCase()))


            console.log('res: ', res);

            this.setState({ filterBookingData: res })
        })

    }
    render() {
        console.log(this.state.mallID, "iddddddddddddddddddddddddd")
        console.log(this.state.listingUploadFiles, "files.......")
        console.log(this.state.listingImage, "simpleFiles.......")

        return (
            <React.Fragment>
                <Loader loading={this.state.loading} />
                <Header subHeading={'Manage all details about listings.'} heading={'Listings'} search

                    onSearch={this.state.search}
                    getOnSearch={(e) => this.searchHandler(e)} />


                <div style={{ backgroundColor: "white", position: "relative" }} className="container-fluid ">

                    <div style={{ backgroundColor: "white" }} className="row p-2">
                        <div className="col-md-6 p-0">
                            <div>
                                <p className="edit-profile-txt">Listing Details</p>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end" }} className="col-md-6 p-0">
                            <div className="listing-btns-div">
                                <div>
                                    <button onClick={() => this.setState({ visible: true, name: '', price: '', description: '', discounts: '', mallID: '' })} className="listing-btns"><p className="listing-btn-txt">Add Listing</p></button>
                                </div>
                                {/* <div>
                                <button className="listing-btns"><p className="listing-btn-txt">Update Listing</p></button>
                            </div> */}
                                {/* <div>
                                <button className="listing-btns"><p className="listing-btn-txt">Delete Listing</p></button>
                            </div> */}
                                <div>
                                    {/* <button className="listing-btns"><p className="listing-btn-txt">Category</p></button> */}
                                    <PickerSelect
                                        color={"#4D4D4D"}
                                        border={"rgba(206, 204, 204, 0.61)"}
                                        fontSize={14}
                                        height={37}
                                        minWidth={"220px"}
                                        list={this.state.allLocation} value={this.state.location} label={"Category"} noBold
                                        onChange={(e) => this.getLocation(e)} />

                                </div>
                            </div>
                        </div>
                        <div className="long-grey-line"></div>
                    </div>
                    {
                        this.state.filterBookingData.length > 0 &&
                        this.state.filterBookingData.map(e =>
                            <div style={{ backgroundColor: "white", paddingBottom: "2%" }} className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="list-card">
                                            <div className="container-fluid p-0">
                                                <div className="row">
                                                    <div className="col-xl-3 col-md-5" >
                                                        {/* <img src={salon}></img> */}
                                                        <Carousel fade>
                                                            {
                                                                e.images.map((item, indx) =>
                                                                    <Carousel.Item key={indx}>
                                                                        <img style={{ width: "250px", height: "165px", borderRadius: "10px" }} src={item}></img>
                                                                    </Carousel.Item>
                                                                )
                                                            }

                                                        </Carousel>
                                                    </div>
                                                    <div className="col-xl-9 col-md-7" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "-2%" }}>
                                                        <div style={{ marginLeft: "2%" }}>

                                                            <p className="saloon">{e?.name}</p>
                                                            <div style={{ display: "flex" }}>
                                                                <div> <p className="pricing">Pricing:</p></div>
                                                                <div><p className="price-value">{e?.price} PKR</p></div>
                                                            </div>
                                                            <div style={{ display: "flex" }}>
                                                                <div> <p className="pricing">Discounts:</p></div>
                                                                <div><p className="price-value2">20% off</p></div>
                                                            </div>
                                                            {/* <div style={{ display: "flex" }}>
                                                                <div> <p className="pricing">Festivals</p></div>
                                                                <div><p className="eid">Eid-ul-Azha</p></div>
                                                            </div> */}
                                                            <div style={{ display: "flex" }}>
                                                                <div> <p className="pricing">Category:</p></div>
                                                                <div><p className="hair">{e?.categoryID?.categoryName}</p></div>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", justifyContent: "space-between", width: "80px", marginRight: "4%" }}>
                                                            <div>
                                                                <BsEye onClick={() => this.setEditValues2(e)} size={20} color="grey" />
                                                            </div>
                                                            <div style={{ cursor: "pointer" }} onClick={() => this.setEditValues(e)}>
                                                                <FaRegEdit size={20} color="grey" />
                                                            </div>
                                                            <div style={{ cursor: "pointer" }} onClick={() => this.deleteHandler(e?._id)}>
                                                                <RiDeleteBinLine size={20} color="red" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {/* <Modal
                    open={this.state.modalopen ? true : false}
                    onClick={() => this.setState({ modalopen: false })}
                    haeding={"Add Listing"}
                /> */}
                    {/* <EditModal
                    open={this.state.editmodalopen ? true : false}
                    onClick={() => this.setState({ editmodalopen: false })}
                    haeding={"Edit Listing"}
                /> */}
                    {/* <AlertModal
                    visible={this.state.visible ? true : false}
                    selectVisible={e => this.setState({ visible: e })}
                    onClick={() => this.setState({ visible: false })} /> */}
                    <Modal
                        open={this.state.visible ? true : false}
                        onClick={() => this.setState({ visible: false })}
                        onCreate={() => this.addListings()}
                        allMalls={this.state.allMalls}
                        mallDrop={this.state.mallDrop}
                        getMallId={e => this.getMallId(e)}
                        mallID={this.state.mallID}
                        name={this.state.name}
                        description={this.state.description}
                        price={this.state.price}
                        discounts={this.state.discounts}
                        getUploadFiles={(e) => this.setState({ listingUploadFiles: e })}
                        selectedImages={(e) => this.setState({ listingImage: e })}
                        getName={(e) => this.setState({ name: e })}
                        getDescription={(e) => this.setState({ description: e })}
                        getPrice={(e) => this.setState({ price: e })}
                        getDiscounts={(e) => this.setState({ discounts: e })}
                    />
                    <ModalForEdit
                        open={this.state.visibleEdit ? true : false}
                        onClick={() => this.setState({ visibleEdit: false })}
                        onUpdate={() => this.editHandler()}
                        allMalls={this.state.allMalls}
                        mallDrop={this.state.mallDrop}
                        getMallId={e => this.getMallId(e)}
                        mallID={this.state.mallID}
                        name={this.state.name}
                        images={this.state.listingImage}
                        description={this.state.description}
                        price={this.state.price}
                        discounts={this.state.discounts}
                        getUploadFiles={(e) => this.setState({ listingUploadFiles: e })}
                        selectedImages={(e) => this.setState({ listingImage: e })}
                        getName={(e) => this.setState({ name: e })}
                        getDescription={(e) => this.setState({ description: e })}
                        getPrice={(e) => this.setState({ price: e })}
                        getDiscounts={(e) => this.setState({ discounts: e })}
                    />
                    <ModalForView
                        open={this.state.visibleView ? true : false}
                        onClick={() => this.setState({ visibleView: false })}
                        onUpdate={() => this.editHandler()}
                        allMalls={this.state.allMalls}
                        mallDrop={this.state.mallDrop}
                        getMallId={e => this.getMallId(e)}
                        mallID={this.state.mallID}
                        name={this.state.name}
                        images={this.state.listingImage}
                        description={this.state.description}
                        price={this.state.price}
                        discounts={this.state.discounts}
                        getUploadFiles={(e) => this.setState({ listingUploadFiles: e })}
                        selectedImages={(e) => this.setState({ listingImage: e })}
                        getName={(e) => this.setState({ name: e })}
                        getDescription={(e) => this.setState({ description: e })}
                        getPrice={(e) => this.setState({ price: e })}
                        getDiscounts={(e) => this.setState({ discounts: e })}
                    />

                </div>
            </React.Fragment>
        )
    }
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,
        saloon: state.saloon,

    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _setStore: payload => dispatch(action.setSaloon(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(Listings));