import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';



const VideoModal = ({ open, onClick, haeding, videoLink }) => {
    // const [youtubeID] = useState('TGe3pS5LqEw')
    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open}>
            <DialogTitle id="dialog-title">{haeding}</DialogTitle>
            <DialogActions>
                <Button onClick={onClick}><p style={{ fontSize: "12px", margin: 0 }}>{'Skip>>'}</p></Button>
            </DialogActions>
            <DialogContent>
                <div style={{ height: '400px', width: "550px" }}>
                    {/* <p style={{ width: "100%", position: "absolute", zIndex: 1, color: "wh", left: 540 }}>Skip</p> */}
                    {/* <iframe className='video'
                        width={"100%"}
                        height={"100%"}
                        title='Youtube player'
                        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                        src={`https://youtube.com/watch?v=${youtubeID}?autoplay=0`}>
                    </iframe> */}
                    <ReactPlayer url={videoLink} controls={true}
                        width={"100%"}

                        height={"100%"}

                    />
                </div>

            </DialogContent>

        </Dialog>
    )
}

export default VideoModal;