import React, { useState } from 'react';
// import "../css/Sidebar.css";
import { BiCalendar } from "react-icons/bi";
import { FaClipboardList } from "react-icons/fa";
import { HiUser } from "react-icons/hi";
import { MdOutlineMessage } from "react-icons/md";
import { RiLogoutBoxRFill } from "react-icons/ri";
import { connect } from 'react-redux';
import store from '../store';
import action from '../store/action';
import { withRouter } from './withRouter';
import { AiOutlineDollar } from "react-icons/ai"

const Sidebar = (props) => {
    const path = window.location.pathname;
    const [text, setText] = useState(false);
    const toggle = () => {
        setText(true)
    }
    const logout = () => {

        store.dispatch(action.logout())
        props.history("/")

    }
    const toggleLeave = () => {
        setText(false);
    }

    return (
        <div className='main-div-sidebar'>
            <div className='logo-flex'>
                <div className='sidebar'>
                    <div className='logo-sidebar'><img src={require("../assets/hermoso-logo.jpg")} alt="" /></div>
                </div>
            </div>
            <div className='link-sidebar'>
                <div className={path === "/" ? "text-icon-sel" : 'text-icon'} onClick={() => props.history("/")}>
                    <div className={path === "/" ? "side-bar-icon-sel" : 'side-bar-icon'}><HiUser /></div>
                    <p className={path === "/" ? "icon-para-sel" : 'icon-para'}>Profile</p>
                </div>
                <div className={path === "/Bookings" ? "text-icon-sel" : 'text-icon'} onClick={() => props.history("/Bookings")}>
                    <div className={path === "/Bookings" ? "side-bar-icon-sel" : 'side-bar-icon'}><BiCalendar /></div>
                    <p className={path === "/Bookings" ? "icon-para-sel" : 'icon-para'}>Bookings</p>
                </div>
                <div className={path === "/Listings" ? "text-icon-sel" : 'text-icon'} onClick={() => props.history("/Listings")}>
                    <div className={path === "/Listings" ? "side-bar-icon-sel" : 'side-bar-icon'}><FaClipboardList /></div>
                    <p className={path === "/Listings" ? "icon-para-sel" : 'icon-para'}>Listings</p>
                </div>
                <div className={path === "/transaction" ? "text-icon-sel" : 'text-icon'} onClick={() => props.history("/transaction")}>
                    <div className={path === "/transaction" ? "side-bar-icon-sel" : 'side-bar-icon'}><AiOutlineDollar /></div>
                    <p className={path === "/transaction" ? "icon-para-sel" : 'icon-para'}>Pay to hermoso</p>
                </div>
                <div className={path === "/Support" ? "text-icon-sel" : 'text-icon'} onClick={() => props.history("/chat")}>
                    <div className={path === "/Support" ? "side-bar-icon-sel" : 'side-bar-icon'}><MdOutlineMessage /></div>
                    <p className={path === "/Support" ? "icon-para-sel" : 'icon-para'}>Chat</p>
                </div>
                <div onClick={logout} className='text-icon'>
                    <div className='side-bar-icon'><RiLogoutBoxRFill /></div>
                    <p className='icon-para'>Logout</p>
                </div>
            </div>
        </div>
    )
}

const mapStatetoProps = (state) => {
    return {
        user: state.user,
        lang: state.lang
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        logout: () => dispatch(action.logout()),


    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(Sidebar));
