import React, { useState, useEffect } from 'react';
import doc from "../assets/doc.png"
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';

import DropDown from './DropDown';

import { TiDelete } from "react-icons/ti";


export default function NewPickerComp({ onUpdate,
    getLinks,
    selectedImages,
    images,
    multiple,
    getUploadFiles,
    type
}) {

    const [imgData, setImgData] = useState(images ? images : []);
    const [uploadFiles, setUploadFiles] = useState([])


    const imageHandler = (e) => {

        const arr = Array.from(e.target.files)
        console.log('Gallery....')
        setImgData([])
        setUploadFiles(arr);
        selectedImages(arr)
        getUploadFiles(arr)


    }

    useEffect(() => {
        getImages()
    }, [uploadFiles])

    useEffect(() => {
        setImgData(images)
    }, [images])

    const getImages = () => {
        uploadFiles.forEach((item, index) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {

                    setImgData(s => [...s, reader.result])
                }
            }
            reader.readAsDataURL(item)

        })
    }
    return (

        <div className='image-div'>

            <div className="form-div"  >
                <span className='inner'>Select image here</span>
                <div style={{ width: 100, position: "relative", }} >
                    <span className='browser' >Browse</span>
                    <input style={{ opacity: 0, position: "absolute", left: -30 }} type="file" className='file' multiple={multiple} accept='image/*' onChange={imageHandler} />
                </div>
            </div>

            <div className='cont'>
                {
                    imgData &&
                    imgData?.map((item, index) =>
                        <div key={index} className='image'>
                            {
                                type ?
                                    <img src={doc}></img> :
                                    <img src={item} alt="" />
                            }

                            <span style={{ marginBottom: "5%" }} onClick={() => setImgData(imgData.filter((e) => e !== item))}>×</span>
                        </div>
                    )
                }
            </div>
        </div>


    )
}
