import React, { Component } from "react";
import { GrStatusGood } from "react-icons/gr"
import { MdOutlineCancel } from "react-icons/md"
import { FaRegEdit } from "react-icons/fa"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import roundpic from "../assets/round-pic.png"
import { RiDeleteBinLine } from "react-icons/ri"
import AlertDialog from "../components/AlertModal";
import AlertModal from "../components/AlertModal";
import Modal from "../components/Modal";
import swal from "sweetalert";
import EditModal from "../components/EditModal";
import Header from "../components/Header";
import { withRouter } from "../components/withRouter";
import { connect } from "react-redux";
import action from "../store/action";
import { editbooking, getAllBooking } from "../Path/Path";
import moment from "moment";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";

class Bookings extends Component {
    state = {
        visible: false,
        editvisible: false,
        bookingData: [],
        filterBookingData: [],
        loading: false,
        onSearch: "",
        page: 1,
    }
    deleteHandler = () => {
        swal({
            title: "Alert?",
            text: "Are you sure to delete?",
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    swal("Deleted!", " deleted successful ", "success");
                }
            })
    }
    componentDidMount() {

        this.getAllBookingsByStoreID()

    }
    getAllBookingsByStoreID = async () => {
        this.setState({ loading: true })
        let res = await getAllBooking(this.props.saloon._id)
        if (res.success) {
            this.setState({ bookingData: res.docs, filterBookingData: res.docs })

        }
        this.setState({ loading: false })
    }
    editbookingstatus = async (elem, type) => {
        this.setState({ loading: true })
        let body = {
            status: type === "tick" ? "completed" : "cancelled"
        }
        await editbooking(elem._id, body)
        await this.getAllBookingsByStoreID()
        this.setState({ loading: false })
    }
    searchHandler = (e) => {
        this.setState({ onSearch: e }, () => {
            if (!this.state.onSearch) {
                this.setState({ filterBookingData: this.state.bookingData })
                return
            }
            const res = this.state.bookingData.filter(i => i.serviceID?.name.toLowerCase().includes(this.state.onSearch.toLowerCase()))


            console.log('res: ', res);

            this.setState({ filterBookingData: res })
        })

    }

    render() {
        let type
        const totalPage = parseInt((this.state.bookingData.length / 12) + 1)
        const elem = 12;
        return (
            <React.Fragment>
                <Loader loading={this.state.loading} />
                <Header subHeading={'Manage your bookings details.'} heading={'Bookings'}
                    search
                    onSearch={this.state.search}
                    getOnSearch={(e) => this.searchHandler(e)}
                />

                <div style={{ backgroundColor: "#FCFCFF" }}>
                    <div className="container-fluid p-0">
                        <div style={{ display: "flex", }} className="row p-5  ">
                            <div style={{ backgroundColor: "#FCFCFF" }} className="col-md-12 ">
                                <div className="booking-card-div">
                                    <p style={{ fontSize: "22px", fontWeight: 600 }}>Bookings</p>

                                    {/* <div className="booking-card">

                                        <div >
                                            <p style={{ fontSize: "14px", fontWeight: 400, color: "#AEAEAE" }}>User</p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: "14px", fontWeight: 400, color: "#AEAEAE", marginLeft: "-98%" }}>Date</p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: "14px", fontWeight: 400, color: "#AEAEAE", marginLeft: "18%", width: "100px" }}>Service Type</p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: "14px", fontWeight: 400, color: "#AEAEAE", marginLeft: "18%", width: "100px" }}>Price</p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: "14px", fontWeight: 400, color: "#AEAEAE", marginLeft: "18%", width: "100px" }}>Hermoso Percentage</p>
                                        </div>

                                        <div>
                                            <p style={{ fontSize: "14px", fontWeight: 400, color: "#AEAEAE", marginLeft: "-98%" }}>Status</p>
                                        </div>
                                        <div>
                                            <p style={{ fontSize: "14px", fontWeight: 400, color: "#AEAEAE", marginLeft: "-28%" }}>Action</p>
                                        </div>
                                    </div> */}
                                    {/* {
                                        this.state.bookingData.map(e =>
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div >
                                                        <p style={{ fontSize: "14px", fontWeight: 400, color: "#404040" }}>{e.userID?.userName}</p>
                                                    </div>
                                                    <div>
                                                        <p style={{ fontSize: "14px", fontWeight: 400, color: "#C7C7C7" }}>{moment(e?.createdAt).format('HH:MM A')}</p>
                                                    </div>
                                                    <div>
                                                        <p style={{ fontSize: "14px", fontWeight: 400, color: "#C7C7C7" }}>{e.serviceID?.name}</p>
                                                    </div>
                                                    <div>
                                                        <p style={{ fontSize: "14px", fontWeight: 400, color: "#C7C7C7" }}>{e.totalPrice}</p>
                                                    </div>
                                                    <div>
                                                        <p style={{ fontSize: "14px", fontWeight: 400, color: "#C7C7C7" }}>{e.serviceID?.hermosoPercentage}</p>
                                                    </div>


                                                    <div>
                                                        <p className={e.status === "completed" ? "green" : e.status === "pending" ? "yellow" : e.status === "cancelled" ? "red" : "pink"}>{e.status}</p>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "50px" }}>
                                                        <div>
                                                            <GrStatusGood onClick={() => this.editbookingstatus(e, type = "tick")} size={13} color={"#2A5555"} />
                                                        </div>
                                                        <div style={{ cursor: "pointer" }} >
                                                            <MdOutlineCancel onClick={() => this.editbookingstatus(e, type = "cross")} size={13} color={"#EA0606"} />
                                                        </div>
                                                        <div style={{ cursor: "pointer" }} onClick={() => this.setState({ visible: true })}>
                                                            <FaRegEdit size={13} color={"#2A5555"} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="booking-line"></div>
                                            </div>
                                        )
                                    } */}
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>User</th>
                                                <th>Date</th>
                                                <th>Service Type</th>
                                                <th>TotalPrice</th>
                                                <th style={{ width: "130px" }}>Hermoso Percentage</th>
                                                <th>Remaining</th>
                                                <th>Status</th>
                                                <th>Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.filterBookingData.length !== 0 ? this.state.filterBookingData
                                                .slice((this.state.page - 1) * elem, (this.state.page * elem) + elem).reverse().map((elem, index) =>
                                                    <tr >
                                                        <td>{elem?.userID?.userName}</td>
                                                        <td>{moment(elem?.createdAt).format('DD/MM/YYYY')}</td>
                                                        <td>{elem?.serviceID?.name} </td>
                                                        <td>{elem?.totalPrice}</td>
                                                        <td>  {Number(elem?.totalPrice) * Number(elem?.serviceID?.hermosoPercentage?.replace('%', '')) / 100} </td>
                                                        <td>{Number(elem?.totalPrice) - (Number(elem?.totalPrice) * Number(elem?.serviceID?.hermosoPercentage?.replace('%', '')) / 100)}</td>
                                                        <td>   <p className={elem.status === "completed" ? "green" : elem.status === "pending" ? "yellow" : elem.status === "cancelled" ? "red" : "pink"}>{elem.status}</p> </td>
                                                        <td>
                                                            <div style={{ display: "flex", justifyContent: "space-between", width: "50px" }}>
                                                                <div style={{ cursor: "pointer" }}>
                                                                    <GrStatusGood onClick={() => this.editbookingstatus(elem, type = "tick")} size={15} color={"#2A5555"} />
                                                                </div>
                                                                <div style={{ cursor: "pointer" }} >
                                                                    <MdOutlineCancel onClick={() => this.editbookingstatus(elem, type = "cross")} size={15} color={"#EA0606"} />
                                                                </div>
                                                                {/* <div style={{ cursor: "pointer" }} onClick={() => this.setState({ visible: true })}>
                                                                <FaRegEdit size={13} color={"#2A5555"} />
                                                            </div> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : null
                                            }
                                        </tbody>
                                    </table>
                                    <Pagination page={this.state.page} setPage={(e) => this.setState({ page: e })} totalPage={totalPage} />
                                </div>
                            </div>
                            {/* <div style={{ backgroundColor: "#FCFCFF", }} className="col-md-6 ">
                            <div className="booking-card-div2">
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <p style={{ fontSize: "1.8rem", fontWeight: 500 }}>Calender</p>
                                    </div>
                                    <div style={{ width: "80px" }}>
                                        <div style={{ display: "flex", marginRight: "2%" }}>
                                            <div className="circle"></div>
                                            <div>
                                                <p >Booked</p>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", marginRight: "2%" }}>
                                            <div className="circle2"></div>
                                            <div>
                                                <p > Not Booked</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Calendar onChange={this.onChange} value={this.value} />
                                <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                    <div>
                                        <button className="calender-btn1">Cancel</button>
                                    </div>
                                    <div>
                                        <button className="calender-btn2">Set booking</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        </div>
                    </div>
                    {/* <div className="container-fluid p-0">
                        <div style={{ display: "flex", }} className="row p-5  ">
                            <div style={{ backgroundColor: "#FCFCFF" }} className="col-md-12">
                                <div className="booking-card-div">
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div>
                                            <p style={{ fontSize: "22px", fontWeight: 600 }}>Chat</p>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "150px" }}>
                                            <div>
                                                <button className="msg-btn"><p className="msg-txt">Inbox</p></button>
                                            </div>
                                            <div>
                                                <button className="msg-btn2"><p className="msg-txt2">Send Message</p></button>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        Array(20).fill().map(e =>
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <div style={{ display: "flex" }} >
                                                        <img className="txt-img" src={roundpic}></img>
                                                        <div style={{ paddingLeft: "2%" }}>
                                                            <p style={{ width: "145px", fontSize: "18px", fontWeight: 500, color: "black", marginBottom: "-1%" }}>Marvin McKinney</p>
                                                            <p style={{ width: "100%", maxWidth: "239px", minWidth: "130px", fontSize: "14px", fontWeight: 300, color: "#808080", marginTop: 0, overflow: "hidden" }}>Hello, how may I help you? · 10:09 am</p>
                                                        </div>
                                                    </div>



                                                    <div style={{ display: "flex", justifyContent: "space-between", width: "50px" }}>

                                                        <div style={{ cursor: "pointer" }} onClick={() => this.setState({ editvisible: true })}>
                                                            <FaRegEdit size={15} color="#2A5555" />
                                                        </div>
                                                        <div style={{ cursor: "pointer" }} onClick={() => this.deleteHandler()}>
                                                            <RiDeleteBinLine size={15} color="#EA0606" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="booking-line2"></div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        

                        </div>
                    </div> */}
                    {/* <AlertModal
                    visible={this.state.visible ? true : false}
                    selectVisible={e => this.setState({ visible: e })}
                    onClick={() => this.setState({ visible: false })} /> */}
                    <Modal
                        open={this.state.visible ? true : false}
                        onClick={() => this.setState({ visible: false })}
                    />
                    <EditModal
                        open={this.state.editvisible ? true : false}
                        onClick={() => this.setState({ editvisible: false })}
                    />
                </div>
            </React.Fragment>
        )
    }
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,
        saloon: state.saloon,

    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _setStore: payload => dispatch(action.setSaloon(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(Bookings));