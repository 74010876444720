import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import VideoModal from "../components/VideoModal";
import { withRouter } from "../components/withRouter";
import { addTransaction, editProfile, editSaloon, getAllTransactionByStoreID, getStoreByOwnerId, getUserByEmail } from "../Path/Path";
import action from "../store/action";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import NewPickerComp from "../components/NewPickerComp";
import getImageURL from "../Config/UploadImageFunction";
import Header from "../components/Header";
import moment from "moment";
import { AiOutlineEye } from "react-icons/ai";
import "../css/Table.css";
import { RiDeleteBinLine } from "react-icons/ri";
import Pagination from "../components/Pagination";
import TransactionModal from "../components/TransactionModal";
import { TiArrowForward, TiDelete } from "react-icons/ti";
import TransactionAddModal from "../components/TransactionAddModal";
import Loader from "../components/Loader";
class Transaction extends Component {
    state = {
        allUsers: [],
        page: 1,
        visible: false,
        receiptImage: "",
        visibleAdd: false,
        price: "",
        listing: [],
        transactionImage: [],
        transactionImageNew: [],
        transactionUploadFiles: [],
        loading: false,
        filterBookingData: [],

        onSearch: "",

    }
    getAllTransaction = async () => {
        this.setState({ loading: true })
        let res = await getAllTransactionByStoreID(this.props.saloon._id)

        if (res.success) {
            this.setState({ allUsers: res.docs, page: 1, filterBookingData: res.docs })

        }
        this.setState({ loading: false })
    }
    async componentDidMount() {
        await this.getAllTransaction()
    }
    refreshData = async () => {
        this.setState({ loading: true })
        await this.getAllTransaction()
        this.setState({ loading: false })
    }
    addTransaction = async () => {
        this.setState({ visibleAdd: false })
        this.setState({ loading: true })
        let linksTransaction = await this.updateHandlerTransactionImage()
        let body = {
            total: this.state.price,
            bookingIds: [],
            receiptImage: linksTransaction[0],
            salonID: this.props.saloon?._id,
            status: "pending"
        }

        await addTransaction(body)
            .then(async (res) => {
                if (res?.success) {
                    await this.getAllTransaction()
                    this.setState({ visibleAdd: false })
                    toast.success(res?.message)

                }
                else {
                    toast.warn(res?.message)
                    this.setState({ loading: false })
                }
            }).catch((err) => {
                toast.warn("Something went wrong")
                this.setState({ loading: false })
            })

        this.setState({ price: "" })
    }
    updateHandlerTransactionImage = async () => {
        // setLoading(true)
        const promises = this.state.transactionUploadFiles?.map(f => getImageURL(f, null,))
        const links = await Promise.all(promises)
        console.log(links, "links....sheikh");
        return links
        // setLoading(false)

    }
    searchHandler = (e) => {
        this.setState({ onSearch: e }, () => {
            if (!this.state.onSearch) {
                this.setState({ filterBookingData: this.state.allUsers })
                return
            }
            const res = this.state.allUsers.filter(i => i.salonID?.name.toLowerCase().includes(this.state.onSearch.toLowerCase()))


            console.log('res: ', res);

            this.setState({ filterBookingData: res })
        })

    }
    render() {
        const totalPage = parseInt((this.state.allUsers.length / 12) + 1)
        const elem = 12;

        return (
            <React.Fragment>
                <Loader loading={this.state.loading} />
                <Header subHeading={'Manage your transactions details.'} heading={'Pay to hermoso'} search
                    onSearch={this.state.search}
                    getOnSearch={(e) => this.searchHandler(e)}
                />
                <div style={{ width: "98%", display: "flex", justifyContent: "flex-end", marginTop: "2%" }}>
                    <button onClick={() => this.setState({ visibleAdd: true })} className="transaction-btn">Pay to hermoso</button>
                    <div onClick={() => this.refreshData()} className='account-icon'>
                        <TiArrowForward />
                    </div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Transaction Id</th>
                            <th>Store Name</th>
                            <th>Price</th>
                            <th>Receipt Image</th>
                            <th>Created At</th>
                            <th>Action</th>


                        </tr>
                    </thead>
                    <tbody>
                        {this.state.filterBookingData.length !== 0 ? this.state.allUsers
                            .slice((this.state.page - 1) * elem, (this.state.page * elem) + elem).reverse().map((elem, index) =>
                                <tr >
                                    <td>{elem?._id}</td>
                                    <td>{elem?.salonID?.name}</td>
                                    <td>{elem?.total}</td>
                                    <td>
                                        <img style={{ width: "50px", height: "50px" }} src={elem?.receiptImage}></img>
                                    </td>

                                    <td>{elem?.createdAt}</td>

                                    <td>
                                        <div className='flex-edit-del'>

                                            <div style={{ marginLeft: "19%" }} onClick={() => this.setState({ visible: true, receiptImage: elem.receiptImage })} className='eye'><AiOutlineEye /></div>
                                            {/* <div className='edit' onClick={""}><FiEdit color={"#FFB219"} /></div> */}
                                            {/* <div className='del' ><RiDeleteBinLine /></div> */}

                                        </div>
                                    </td>
                                </tr>
                            ) : null
                        }
                    </tbody>
                </table>
                <Pagination page={this.state.page} setPage={(e) => this.setState({ page: e })} totalPage={totalPage} />
                <TransactionModal
                    open={this.state.visible ? true : false}
                    onClick={() => this.setState({ visible: false })}
                    receiptImage={this.state.receiptImage}

                />
                <TransactionAddModal
                    open={this.state.visibleAdd ? true : false}
                    onClick={() => this.setState({ visibleAdd: false })}
                    onCreate={() => this.addTransaction()}
                    price={this.state.price}
                    getUploadFiles={(e) => this.setState({ transactionUploadFiles: e })}
                    selectedImages={(e) => this.setState({ transactionImage: e })}
                    getPrice={(e) => this.setState({ price: e })}
                />
            </React.Fragment>
        )
    }
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,
        saloon: state.saloon,

    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _setStore: payload => dispatch(action.setSaloon(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(Transaction));