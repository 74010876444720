import { FormControl, MenuItem, Select } from '@material-ui/core';
import * as React from 'react';


export default ({ list, value, onChange, minWidth = 220, minHeight, width, label, noBold, height, fontSize, color, border, placeholder }) => {

    return (
        <div className='listing-btns'>
            {/* <p className="listing-btn-txt">Category</p> */}
            <FormControl sx={{ display: "flex", flex: 1 }} >
                <Select style={{ height: height || 45, width: "100%", fontWeight: noBold ? "normal" : "bold", fontSize: fontSize, color, border: border }}

                    value={value}
                    onChange={e => onChange(e.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem disabled={true} value={""} >{placeholder || label || "Select Option"}</MenuItem>
                    {
                        list.map((item, index) => (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                        ))
                    }

                </Select>
            </FormControl>
        </div>

    );
}
