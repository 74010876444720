import React, { Component } from "react";
import Sidebar from "../components/Sidebar";
import VideoModal from "../components/VideoModal";
import { withRouter } from "../components/withRouter";
import { editProfile, editSaloon, getStoreByOwnerId, getUserByEmail } from "../Path/Path";
import action from "../store/action";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import NewPickerComp from "../components/NewPickerComp";
import getImageURL from "../Config/UploadImageFunction";
import Header from "../components/Header";
import Loader from "../components/Loader";
import PlacesAutocomplete from 'react-places-autocomplete';
import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';
import MyPickerComp from "../components/MyPickerComp";
let arr = [{
    "day": "Monday",
    "openingTime": "04:00",
    "closingTime": "13:00"
},
{
    "day": "Tuesday",
    "openingTime": "05:00",
    "closingTime": "07:00"
}
]
class Profile extends Component {
    state = {
        modalopen: true,
        name: '',
        about: "",
        phone: "",
        website: "",
        timingsArr: [],
        offers: [],
        offerImage: [],
        offerImageNew: [],
        offersUploadFiles: [],
        ntn: "",
        galleryImage: [],
        galleryImageNew: [],
        gallery: [],
        galleryUploadFiles: [],
        mondayo: "",
        mondayc: "",
        tuesdayo: "",
        tuesdayc: "",
        wednesdayo: "",
        wednesdayc: "",
        thursdayo: "",
        thursdayc: "",
        fridayo: "",
        fridayc: "",
        saturdayo: "",
        saturdayc: "",
        sundayo: "",
        sundayc: "",
        logoImage: [],
        logoImageNew: [],
        logo: '',
        logoUploadFiles: [],
        coverImage: [],
        coverImageNew: [],
        cover: '',
        coverUploadFiles: [],
        zipCode: "",
        statehermoso: "",
        businessAddress: "",
        incorporationLetter: "",
        incorporationLetterNew: [],
        incorporation: "",
        videoLink: "",
        previousVideoLink: "",
        incorporationUploadFiles: [],
        phoneNumber: "",
        country: "",
        city: "",
        loading: false,
        locationnew: "",
        location: {
            latitude: 0,
            longitude: 0
        },

    }
    edithandler = async () => {
        this.setState({ loading: true })
        let offerLinks = this.props.store?.offers
        let galleryLinks = this.props.store?.gallery
        let logoLink = ""
        let coverLink = ''
        let incorporationLetterLink = ''

        if (this.state.offersUploadFiles.length)
            offerLinks = await this.updateHandlerOffers()



        if (this.state.galleryUploadFiles.length)
            galleryLinks = await this.updateHandlerGallery()


        if (this.state.logoUploadFiles.length > 0)
            logoLink = await this.updateHandlerLogo()

        console.log(this.state.logoUploadFiles, "logoimgaenwew")
        console.log(logoLink, "logoglink")
        if (this.state.coverImageNew.length > 0) {
            console.log("coverImageNew")
            coverLink = await this.updateHandlerCover()
        }
        if (this.state.incorporationLetterNew.length > 0) {
            console.log("incorporationLetterNew")
            incorporationLetterLink = await this.updateHandlerIncorporation()
        }



        let body = {
            name: this.state.name,
            about: this.state.about,
            address: this.state.businessAddress,
            offers: offerLinks,
            gallery: galleryLinks,
            ownerID: this.props.user._id,
            timings: this.state.timingsArr,
            // logo: logoLink[0],
            logo: logoLink[0],
            cover: coverLink[0],
            ntn: this.state.ntn,
            zipCode: this.state.zipCode,
            country: this.state.country,
            state: this.state.statehermoso,
            city: this.state.city,
            videoLink: this.state.videoLink,
            incorporationLetter: incorporationLetterLink ? incorporationLetterLink : this.props.store?.incorporationLetter,
            phoneNumber: this.state.phoneNumber,
            lat: this.state.location.latitude,
            long: this.state.location.longitude
        }
        console.log(body, "biody")
        await editSaloon(body, this.props.saloon._id)
            .then(async (response) => {
                if (response?.success) {
                    await this.getStoreData()
                    this.setState({ loading: false })
                }
            }

            ).catch((err) => {
                console.log('err: ', err);
                toast.warn(err?.message)
                this.setState({ loading: false })
            })
        this.setState({ loading: false })
    }
    getStoreData = async () => {
        let res = await getStoreByOwnerId(this.props.user?._id)
        if (res?.success) {
            this.props._setStore(res?.store)

            this.setState({
                location: {
                    ...this.state.location, latitude: res?.store?.lat,
                    longitude: res?.store?.long
                }, timingsArr: res.store?.timings,
                name: res?.store?.name, about: res?.store?.about,
                phoneNumber: res?.store?.phoneNumber, ntn: res?.store?.ntn, zipCode: res?.store?.zipCode,
                country: res?.store?.country, statehermoso: res?.store?.state, city: res?.store?.city,
                businessAddress: res?.store?.address, videoLink: res?.store?.videoLink,
                previousVideoLink: res?.store?.videoLink, offerImage: res?.store?.offers,
                galleryImage: res?.store?.gallery, coverImage: [res?.store?.cover],
                logoImage: [res?.store?.logo], incorporationLetter: [res?.store?.incorporationLetter]
            })

        }
    }
    async componentDidMount() {
        await this.getStoreData()

    }

    updateHandlerOffers = async () => {
        // setLoading(true)
        let seprateUrl = this.state.offersUploadFiles.filter(f => f.toString().includes("firebase"))
        let seprateData = this.state.offersUploadFiles.filter(f => !!f?.name)
        console.log('seprateData: ', seprateData.length);

        if (seprateData.length) {

            const promises = seprateData?.map(f => getImageURL(f, null,))
            const links = await Promise.all(promises)

            seprateUrl = seprateUrl.concat(links)
            console.log(links, "links")
            this.setState({ offers: links })
            return seprateUrl
        }
        else {
            return seprateUrl
        }
        // setLoading(false)

    }
    updateHandlerGallery = async () => {
        // setLoading(true)
        let seprateUrl = this.state.galleryUploadFiles.filter(f => f.toString().includes("firebase"))
        let seprateData = this.state.galleryUploadFiles.filter(f => !!f?.name)
        console.log('seprateData: ', seprateData.length);

        if (seprateData.length) {

            const promises = seprateData?.map(f => getImageURL(f, null,))
            const links = await Promise.all(promises)

            seprateUrl = seprateUrl.concat(links)
            console.log(links, "links")
            this.setState({ gallery: links })
            return seprateUrl
        }
        else {
            return seprateUrl
        }
    }
    updateHandlerLogo = async () => {
        // // setLoading(true)
        // const promises = this.state.logoUploadFiles?.map(f => getImageURL(f, null,))
        // const links = await Promise.all(promises)
        // console.log(links, "links....sheikh");
        // this.setState({ logo: links[0] })
        // return links
        // // setLoading(false)
        let seprateUrl = this.state.logoUploadFiles.filter(f => f.toString().includes("firebase"))
        let seprateData = this.state.logoUploadFiles.filter(f => !!f?.name)
        console.log('seprateData: ', seprateData.length);

        if (seprateData.length) {

            const promises = seprateData?.map(f => getImageURL(f, null,))
            const links = await Promise.all(promises)

            seprateUrl = seprateUrl.concat(links)
            console.log(links, "links")
            this.setState({ logo: links[0] })
            return seprateUrl
        }
        else {
            return seprateUrl
        }

    }
    updateHandlerCover = async () => {
        // // setLoading(true)
        // const promises = this.state.coverUploadFiles?.map(f => getImageURL(f, null,))
        // const links = await Promise.all(promises)

        // this.setState({ cover: links[0] })
        // return links
        // // setLoading(false)
        // let seprateUrl = this.state.coverUploadFiles.filter(f => f.toString().includes("firebase"))
        let seprateData = this.state.coverUploadFiles.filter(f => !!f?.name)
        console.log('seprateData: ', seprateData.length);

        if (seprateData.length) {

            const promises = seprateData?.map(f => getImageURL(f, null,))
            const links = await Promise.all(promises)

            // seprateUrl = seprateUrl.concat(links)
            console.log(links, "links")
            this.setState({ cover: links[0] })
            return seprateData
        }
        else {
            return seprateData
        }

    }
    updateHandlerIncorporation = async () => {
        // setLoading(true)
        const promises = this.state.incorporationUploadFiles?.map(f => getImageURL(f, null,))
        const links = await Promise.all(promises)

        this.setState({ incorporation: links[0] })
        return links
        // setLoading(false)

    }
    timingOnChangeOpening = (index, val) => {
        let temp = [...this.state.timingsArr]
        temp[index].openingTime = val
        this.setState({ timingsArr: temp })
    }
    timingOnChangeClosing = (index, val) => {
        let temp = [...this.state.timingsArr]
        temp[index].closingTime = val
        this.setState({ timingsArr: temp })
    }
    handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const { lat, lng } = await getLatLng(results[0]);
        this.setState({ locationnew: value, location: { latitude: lat, longitude: lng } });


        // this.setState({ area: results[0].address_components[0] && results[0].address_components[0].long_name })
        // this.setState({ city: results[0].address_components[3] && results[0].address_components[3].long_name })
        // this.setState({ country: results[0].address_components[7] && results[0].address_components[7].long_name })
    };
    render() {


        return (
            <React.Fragment>
                <Loader loading={this.state.loading} />
                <Header subHeading={'Manage your profile details.'} heading={'Profile'} />

                <div style={{ padding: "3%" }}>
                    <div>
                        <p className="edit-profile-txt">Edit Profile Details</p>
                    </div>
                    <div>
                        <div>
                            <div>
                                <p className="profile-txt">Business Name</p>
                            </div>
                            <div>
                                <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} className="profile-input" placeholder="Business Name"></input>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt"> About </p>
                            </div>
                            <div>
                                <input value={this.state.about} onChange={(e) => this.setState({ about: e.target.value })} className="profile-input" placeholder="About"></input>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt"> Phone </p>
                            </div>
                            <div>
                                <input value={this.state.phoneNumber} onChange={(e) => this.setState({ phoneNumber: e.target.value })} className="profile-input" placeholder="Phone"></input>
                            </div>
                        </div>
                        {/* <div>
                            <div>
                                <p className="profile-txt"> Website </p>
                            </div>
                            <div>
                                <input value={this.state.website} onChange={(e) => this.setState({ website: e.target.value })} className="profile-input" placeholder="Website"></input>
                            </div>
                        </div> */}
                        <div>
                            <div>
                                <p className="profile-txt"> Offers </p>
                            </div>
                            <div style={{ width: "100%" }}>

                                {/* <NewPickerComp getUploadFiles={(e) => this.setState({ offersUploadFiles: e })} images={this.state.offerImage} selectedImages={e => this.setState({ offerImage: e, offerImageNew: e })} multiple={true} /> */}
                                <MyPickerComp images={this.state.offerImage} getUploadFiles={e => this.setState({ offersUploadFiles: e })} multiple={true} />
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt"> NTN </p>
                            </div>
                            <div>
                                <input value={this.state.ntn} onChange={(e) => this.setState({ ntn: e.target.value })} className="profile-input" placeholder="NTN"></input>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt"> Gallery </p>
                            </div>
                            <div style={{ width: "100%" }}>
                                {/* <OffersServiceAddModal
                                        getUploadFiles={(e) => this.setState({ offersUploadFiles: e })}
                                        selectedOffers={this.state.offersUploadFiles}
                                    /> */}
                                {/* <NewPickerComp getUploadFiles={(e) => this.setState({ galleryUploadFiles: e })} images={this.state.galleryImage} selectedImages={e => this.setState({ galleryImage: e, galleryImageNew: e })} multiple={true} /> */}
                                <MyPickerComp images={this.state.galleryImage} getUploadFiles={e => this.setState({ galleryUploadFiles: e })} multiple={true} />
                            </div>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <p className='email'>Timmings</p>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                            <p className='email'>Monday</p>
                        </div>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Opening Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[0]?.openingTime} onChange={(e) => { this.timingOnChangeOpening(0, e.target.value); this.setState({ mondayo: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Closing Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[0]?.closingTime} onChange={(e) => { this.timingOnChangeClosing(0, e.target.value); this.setState({ mondayc: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                            <p className='email'>Tuesday</p>
                        </div>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Opening Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[1]?.openingTime} onChange={(e) => { this.timingOnChangeOpening(1, e.target.value); this.setState({ tuesdayo: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Closing Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[1]?.closingTime} onChange={(e) => { this.timingOnChangeClosing(1, e.target.value); this.setState({ tuesdayc: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                            <p className='email'>Wednesday</p>
                        </div>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Opening Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[2]?.openingTime} onChange={(e) => { this.timingOnChangeOpening(2, e.target.value); this.setState({ wednesdayo: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Closing Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[2]?.closingTime} onChange={(e) => { this.timingOnChangeClosing(2, e.target.value); this.setState({ wednesdayc: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                            <p className='email'>Thursday</p>
                        </div>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Opening Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[3]?.openingTime} onChange={(e) => { this.timingOnChangeOpening(3, e.target.value); this.setState({ thursdayo: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Closing Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[3]?.closingTime} onChange={(e) => { this.timingOnChangeClosing(3, e.target.value); this.setState({ thursdayc: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                            <p className='email'>Friday</p>
                        </div>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Opening Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[4]?.openingTime} onChange={(e) => { this.timingOnChangeOpening(4, e.target.value); this.setState({ fridayo: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Closing Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[4]?.closingTime} onChange={(e) => { this.timingOnChangeClosing(4, e.target.value); this.setState({ fridayc: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                            <p className='email'>Saturday</p>
                        </div>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Opening Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[5]?.openingTime} onChange={(e) => { this.timingOnChangeOpening(5, e.target.value); this.setState({ saturdayo: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Closing Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[5]?.closingTime} onChange={(e) => { this.timingOnChangeClosing(5, e.target.value); this.setState({ saturdayc: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                            <p className='email'>Sunday</p>
                        </div>
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Opening Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[6]?.openingTime} onChange={(e) => { this.timingOnChangeOpening(6, e.target.value); this.setState({ sundayo: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                            <div style={{ width: "48%" }}>
                                <div >
                                    <p className='email2'>Closing Time</p>
                                </div>
                                <div>
                                    <input type="time" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" value={this.state.timingsArr[6]?.closingTime} onChange={(e) => { this.timingOnChangeClosing(6, e.target.value); this.setState({ sundayc: e.target.value }) }} className='register-input' placeholder='Opening Time'></input>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt"> Logo </p>
                            </div>
                            <div style={{ width: "100%" }}>

                                {/* <NewPickerComp getUploadFiles={(e) => this.setState({ logoUploadFiles: e })} images={this.state.logoImage} selectedImages={e => this.setState({ logoImage: e, logoImageNew: e })} multiple={false} /> */}
                                <MyPickerComp images={this.state.logoImage} getUploadFiles={e => this.setState({ logoUploadFiles: e })} multiple={false} />
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt"> Cover </p>
                            </div>
                            <div style={{ width: "100%" }}>

                                {/* <NewPickerComp getUploadFiles={(e) => this.setState({ coverUploadFiles: e })} images={this.state.coverImage} selectedImages={e => this.setState({ coverImage: e, coverImageNew: e })} multiple={false} /> */}
                                <MyPickerComp images={this.state.coverImage} getUploadFiles={e => this.setState({ coverUploadFiles: e })} multiple={false} />
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt">Zip Code</p>
                            </div>
                            <div>
                                <input value={this.state.zipCode} onChange={(e) => this.setState({ zipCode: e.target.value })} className="profile-input" placeholder="Zip Code"></input>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt">Country</p>
                            </div>
                            <div>
                                <input value={this.state.country} onChange={(e) => this.setState({ country: e.target.value })} className="profile-input" placeholder="Country"></input>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt">State</p>
                            </div>
                            <div>
                                <input value={this.state.statehermoso} onChange={(e) => this.setState({ statehermoso: e.target.value })} className="profile-input" placeholder="State"></input>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt">City</p>
                            </div>
                            <div>
                                <input value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} className="profile-input" placeholder="City"></input>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt">Bussiness Address</p>
                            </div>
                            <div>
                                <input value={this.state.businessAddress} onChange={(e) => this.setState({ businessAddress: e.target.value })} className="profile-input" placeholder="Bussiness Address"></input>
                            </div>
                            <div>
                                <p className="profile-txt">Location</p>
                            </div>
                            <div style={{ width: "40%" }}>
                                <PlacesAutocomplete

                                    value={this.state.locationnew}
                                    onChange={(e) => this.setState({ locationnew: e })}
                                    onSelect={this.handleSelect}
                                    required
                                    api
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div style={{ width: "100%" }}>
                                            <input
                                                {...getInputProps({
                                                    placeholder: 'Search Places ...',
                                                    className: 'location-search-input register-input',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                        >
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt"> Incorporation Letter </p>
                            </div>
                            <div style={{ width: "100%" }}>

                                <NewPickerComp getUploadFiles={(e) => this.setState({ incorporationUploadFiles: e })} type={"incorporationLetter"} images={this.state.incorporationLetter} selectedImages={e => this.setState({ incorporationLetter: e, incorporationLetterNew: e })} multiple={false} />

                            </div>
                        </div>
                        <div>
                            <div>
                                <p className="profile-txt"> Youtube Link  </p>
                            </div>
                            <div>
                                <input value={this.state.videoLink} onChange={(e) => this.setState({ videoLink: e.target.value })} className="profile-input" placeholder="(Video link of Youtube)Optional"></input>
                            </div>
                        </div>
                        <div className="two-btn-div">
                            <div>
                                <button onClick={() => this.edithandler()} className="update-btn">
                                    <p className="update-txt">Update Profile</p>
                                </button>
                            </div>
                            <div>
                                <button className="cancel-btn">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                    {this.state.previousVideoLink ?
                        <VideoModal
                            open={this.state.modalopen ? true : false}
                            videoLink={this.state.videoLink}
                            onClick={() => this.setState({ modalopen: false })}

                        /> :
                        null
                    }
                </div>
            </React.Fragment>
        )
    }
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,
        saloon: state.saloon,

    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),
        _setStore: payload => dispatch(action.setSaloon(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(Profile));