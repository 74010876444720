import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
// export const BASE_URL = "https://hermoso-server.herokuapp.com/";
export const BASE_URL = "https://backend.hermoso.app/";

export const PATHS = {
    GET_RECIPIENTS: BASE_URL + "channel/chat/recipients/",
    ADD_RECIPIENTS: BASE_URL + "channel/chat/recipients/add",
    READ_MESSAGES: BASE_URL + "channel/read/",
}

const signup = async (payLoad) => {
    const { data } = await axios.post(`${BASE_URL}user/signup`, payLoad);
    return data;
}
const login = async (payLoad) => {
    const { data } = await axios.post(`${BASE_URL}user/login`, payLoad);
    return data;
}
const editProfile = async (body, _id) => {
    const { data } = await axios.patch(`${BASE_URL}user/edit/${_id}`, body)
    return data;
}
const getUserByEmail = async (email) => {
    const { data } = await axios.get(`${BASE_URL}user/get/by/email/${email}`)
    return data;
}
const getStoreByOwnerId = async (ownerId) => {
    const { data } = await axios.get(`${BASE_URL}store/get/by/${ownerId}`)
    return data;
}
const editSaloon = async (body, _id) => {
    const { data } = await axios.put(`${BASE_URL}store/edit/${_id}`, body)
    return data;
}
const getAllServices = async (_id) => {
    const { data } = await axios.get(`${BASE_URL}service/by/store/${_id}`)
    return data;
}
const deleteService = async (item) => {
    const { data } = await axios.delete(`${BASE_URL}service/delete/${item}`)
    return data;
}
const addService = async (payLoad) => {
    const { data } = await axios.post(`${BASE_URL}service/add`, payLoad);
    return data;
}
const editService = async (_id, payLoad) => {
    const { data } = await axios.put(`${BASE_URL}service/edit/${_id}`, payLoad);
    return data;
}
const verifyCodeForget = async (params) => {
    const { data } = await axios.get(`${BASE_URL}user/send-reset-pass/${params.email}/${params.type}`)
    return data;
}
const resetPassword = async (email, body) => {
    const { data } = await axios.put(`${BASE_URL}user/reset/password/${email}`, body)
    return data;
}

const getCategories = async () => {
    const { data } = await axios.get(`${BASE_URL}category/get`)
    return data;
}
const getAllBooking = async (_id) => {
    const { data } = await axios.get(`${BASE_URL}booking/by/salon/${_id}`)
    return data;
}
const editbooking = async (_id, body) => {
    const { data } = await axios.put(`${BASE_URL}booking/edit/${_id}`, body)
    return data;
}
const getAllTransactionByStoreID = async (_id) => {
    const { data } = await axios.get(`${BASE_URL}transaction/get/by/store/${_id}`)
    return data;
}
const addTransaction = async (payLoad) => {
    const { data } = await axios.post(`${BASE_URL}transaction/add`, payLoad);
    return data;
}
export {
    signup,
    login,
    editProfile,
    getUserByEmail,
    getAllServices,
    deleteService,
    addService,
    editService,
    verifyCodeForget,
    resetPassword,
    getStoreByOwnerId,
    editSaloon,
    getCategories,
    getAllBooking,
    editbooking,
    getAllTransactionByStoreID,
    addTransaction,
}