// import React from 'react';

// import {
//     Button,
//     Dialog,
//     DialogTitle,
//     DialogContent,
//     DialogActions
// } from '@material-ui/core';


// const Modal = ({ open, onClick, haeding }) => {
//     return (
//         <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open}>
//             <DialogTitle id="dialog-title">{haeding}</DialogTitle>
//             <DialogContent>
//                 <div style={{ minHeight: '400px', width: '400px' }}>
//                     <div>
//                         <p style={{ fontWeight: 500, fontSize: "18px" }}>List Title</p>
//                         <input className='modal-input' placeholder='Enter Title'></input>
//                     </div>
//                     <div>
//                         <p style={{ fontWeight: 500, fontSize: "18px" }}>Price</p>
//                         <input className='modal-input' placeholder='Enter Price'></input>
//                     </div>
//                     <div>
//                         <p style={{ fontWeight: 500, fontSize: "18px" }}>Discounts</p>
//                         <input className='modal-input' placeholder='Enter Discount'></input>
//                     </div>
//                     <div>
//                         <p style={{ fontWeight: 500, fontSize: "18px" }}>Festivals</p>
//                         <input className='modal-input' placeholder='Enter Festival'></input>
//                     </div>
//                     <div>
//                         <p style={{ fontWeight: 500, fontSize: "18px" }}>Category</p>
//                         <input className='modal-input' placeholder='Enter Category'></input>
//                     </div>
//                     <div>
//                         <p style={{ fontWeight: 500, fontSize: "18px" }}>Select Image</p>
//                         <input type="file" id="files" name="files" multiple="multiple"></input>
//                     </div>
//                 </div>
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={onClick}>Close</Button>
//             </DialogActions>
//         </Dialog>
//     )
// }

// export default Modal;
import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
// import "../css/Modal.css";
import DropDown from './DropDown';
// import "../css/DropDown.css";
import { TiDelete } from "react-icons/ti";
import { RiImageAddFill } from "react-icons/ri";
import NewPickerComp from './NewPickerComp';
import NewPickerCompForModal from './NewPickerCompForModal';
import getImageURL from '../Config/UploadImageFunction';
import { async } from '@firebase/util';
import { toast } from "react-toastify";

const Modal = ({ open, onClick, name, getName, description, getDescription, price, getPrice, discounts, getDiscounts, mallDrop, getMallId, mallID, onCreate, getUploadFiles, selectedImages, images }) => {
    const [galleryImage, setGalleryImage] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [galleryUploadFiles, setGalleryUploadFiles] = useState([]);
    const updateHandlerGallery = async () => {
        // setLoading(true)
        const promises = galleryUploadFiles?.map(f => getImageURL(f, null,))
        const links = await Promise.all(promises)
        console.log(links, "links....sheikh");
        // this.setState({ gallery: links })
        setGallery(links)
        return links
        // setLoading(false)

    }

    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open} >
            <div className='close-btn' onClick={onClick}><TiDelete /></div>
            <DialogTitle id="dialog-title">
                <h2>{window.location.pathname.split("/")[1].toUpperCase()}</h2>
                <div className='line'></div>
            </DialogTitle>
            <DialogContent>
                <div className="modal-main" >
                    <form>
                        <label htmlFor='username'>Listing Name</label>
                        <input type="text" id='username' placeholder='Listing Name' value={name} onChange={(e) => getName(e.target.value)} required />
                        <label htmlFor='username'>Description</label>
                        <input type="text" id='description' placeholder='Description ' value={description} onChange={(e) => getDescription(e.target.value)} />
                        <label htmlFor='account-type'>Price</label>
                        <input type="text" id='account-type' placeholder='Enter Price' value={price} onChange={(e) => getPrice(e.target.value)} />
                        <label htmlFor='account-type'>Discounts</label>
                        <input type="text" id='account-type' placeholder='Enter Discounts' value={discounts} onChange={(e) => getDiscounts(e.target.value)} />
                    </form>



                </div>
                <div className='drop'>
                    <label>Category</label>
                    <DropDown

                        options={mallDrop}
                        onChange={e => getMallId(e)}
                        value={mallID?.categoryName}
                    />


                </div>
                <div className='image-div'>
                    {/* <label htmlFor='account-type'>Select Image</label> */}
                    <p style={{ fontSize: "14px", fontWeight: 500 }}>Select Image</p>
                    {/* <div className='img-position'>
                        <RiImageAddFill size={70} color={"grey"} />
                    </div>
                    <input style={{ opacity: "0" }} type="file" id='account-type' /> */}
                    <div style={{ width: "100%" }}>
                        <NewPickerCompForModal getUploadFiles={(e) => getUploadFiles(e)} images={images} selectedImages={e => selectedImages(e)} multiple={true} />
                    </div>
                </div>
            </DialogContent>
            <div className='but-flex'>
                <div className='cancel'>
                    <button onClick={onClick}>Cancel</button>
                </div>
                <div className='save-changes'>
                    <button onClick={() => onCreate()}>Save Changes</button>
                </div>
            </div>
        </Dialog>
    )
}

export default Modal;