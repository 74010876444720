import React, { Component } from "react";

import hermoso from "../assets/hermoso.png"
import { withRouter } from "../components/withRouter";
import { CircularProgress } from '@mui/material';
import { toast } from "react-toastify";
import { signup } from "../Path/Path";

class Register extends Component {
    state = {
        email: "",
        password: "",
        loading: false
    }
    signUpHnadler = async () => {
        const body = {
            "userName": "",
            "email": this.state.email,
            "password": this.state.password,
            "phone": "",
            "type": "service provider",
            "image": "",
            "isActive": true,
        }
        if (this.state.email === "") {
            toast.warn("Enter Email")
        }
        else if (this.state.password === "") {
            toast.warn("Enter Password")
        }
        else {
            await this.setState({ loading: true })
            await signup(body)
                .then((res) => {
                    if (res?.success) {
                        toast.success(res?.message)


                        this.props.history("/")


                    }
                    else {
                        toast.warn(res?.message)
                        this.setState({ loading: false })
                    }
                }).catch((err) => {
                    toast.warn("Something went wrong")
                    this.setState({ loading: false })
                })

        }
    }
    render() {
        return (
            <div>

                <div className="container-fluid p-0 background-pattern">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                            <div className='Login-center'>
                                <div style={{ width: "180px", height: "75px" }}>
                                    <img width="100%" height="100%" style={{ objectFit: "contain" }} src={hermoso}></img>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='login-main'>Create your account</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Email</p>
                                </div>
                                <input value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className='register-input' placeholder='Email Address'></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Password</p>
                                </div>
                                <input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className='register-input' placeholder='Password'></input>

                                <button onClick={() => this.signUpHnadler()} loading={this.state.loading} className='signup-btn'>
                                    {
                                        this.state.loading ?
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CircularProgress size={15} style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                            </div>

                                            :
                                            <p className='signup-text'>Sign Up</p>
                                    }
                                </button>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%", marginBottom: "2%" }}>
                                    <div className="line-grey">
                                    </div>
                                    <div><p style={{ color: "grey", margin: "0", fontSize: "20px" }}>or</p></div>
                                    <div className="line-grey">
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        <p style={{ color: "black", }}>Already have member? </p>
                                    </div>
                                    <div onClick={() => this.props.history("/")} >
                                        <p style={{ color: "#2A5555", fontWeight: 500, cursor: "pointer", marginLeft: "5px" }}>Sign in</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="design-bottom">


                    </div> */}

                </div>

            </div>
        )
    }
}
export default withRouter(Register);