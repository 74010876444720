import React, { useState, useEffect } from 'react';
import doc from "../assets/doc.png"
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';

import DropDown from './DropDown';

import { TiDelete } from "react-icons/ti";


export default function MyPickerComp({ onUpdate,

    images,
    multiple,
    getUploadFiles,
    type
}) {

    const [imgData, setImgData] = useState(images ? images : []);
    useEffect(() => {
        setImgData(images)

    }, [images])


    const imageHandler = (e) => {


        const arr = Array.from(e.target.files)
        console.log('arr: ', arr);
        let newArr = [...imgData]
        arr.forEach((item, index) => {
            const reader = new FileReader();
            reader.onload = () => {

                if (reader.readyState === 2) {
                    console.log(arr, "[arr]")
                    !multiple ? setImgData([reader.result]) : setImgData(s => [...s, reader.result])
                    newArr = [...newArr, reader.result]
                    !multiple ? getUploadFiles(arr) : getUploadFiles([...imgData, ...arr])
                }
            }
            reader.readAsDataURL(item)

        })



    }

    const removeImages = (item) => {
        let res = imgData.filter((e) => e !== item)
        getUploadFiles(res)
        setImgData(res)
    }
    return (

        <div className='image-div'>

            <div className="form-div"  >
                <span className='inner'>Select image here</span>
                <div style={{ width: 100, position: "relative", }} >
                    <span className='browser' >Browse</span>
                    <input style={{ opacity: 0, position: "absolute", left: -30 }} type="file" className='file' multiple={multiple} accept='image/*' onChange={imageHandler} />
                </div>
            </div>

            <div className='cont'>
                {
                    imgData &&
                    imgData?.map((item, index) =>
                        <div key={index} className='image'>
                            {
                                type ?
                                    <img src={doc}></img> :
                                    <img src={item} alt="" />
                            }

                            <span style={{ marginBottom: "5%" }} onClick={() => removeImages(item)}>×</span>
                        </div>
                    )
                }
            </div>
        </div>


    )
}
