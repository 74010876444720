import React, { Component } from "react";

import hermoso from "../assets/hermoso.png"
import { withRouter } from "../components/withRouter";
import { login, verifyCodeForget } from "../Path/Path";
import { toast } from "react-toastify";
import { CircularProgress } from '@mui/material';
import jwt_decode from "jwt-decode";

import { connect } from "react-redux";
import store from "../store";
import action from "../store/action";
import PinInput from "react-pin-input";
class ResetPassword extends Component {
    state = {
        email: this.props.location?.state?.email,
        password: "",
        loading: "",
        verifyCode: this.props.location?.state?.code,
        code: "",
    }
    handler = () => {
        if (this.state.code === this.state.verifyCode) {
            this.props.history("/newpassword", { state: { email: this.state.email } })
        }
        else {
            toast.warn('Your code is not valid')
        }
    }
    resendCode = async () => {
        this.setState({ loading: true })
        if (this.state.email === "") {
            toast.warn("Enter Email")

        }

        else {

            let params = {
                email: this.state.email,
                type: "service provider"
            }
            await verifyCodeForget(params)
                .then((res) => {
                    if (res?.success) {
                        this.setState({ verifyCode: res?.code, code: '' })
                    } else {
                        toast.warn(res?.message)
                    }
                    console.log(res, "ressssssssssssssss")

                }).catch((err) => {
                    toast.warn(err.message)

                })

        }
        // this.props.history("/")
        this.setState({ loading: false })
    }

    render() {
        return (
            <div>

                <div className="container-fluid p-0 background-pattern">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                            <div className='Login-center'>
                                <div style={{ width: "180px", height: "75px" }}>
                                    <img width="100%" height="100%" style={{ objectFit: "contain" }} src={hermoso}></img>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='login-main'>OTP</p>
                                </div>

                                <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>

                                    <PinInput

                                        length={5}
                                        initialValue=""

                                        onChange={(value, index) => { this.setState({ code: value }) }}
                                        type="text"
                                        style={{ padding: '12px', justifyContent: "center" }}
                                        inputStyle={{ borderColor: 'rgba(206, 204, 204, 0.61)' }}
                                        inputFocusStyle={{ borderColor: 'rgba(206, 204, 204, 0.61)' }}
                                        onComplete={(value, index) => { }}
                                        autoSelect={true}
                                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                    />
                                </div>


                                <button onClick={() => this.handler()} loading={this.state.loading} className='signup-btn'>
                                    {
                                        this.state.loading ?
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CircularProgress size={15} style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                            </div>

                                            :
                                            <p className='signup-text'>Verify Password</p>
                                    }
                                </button>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%", marginBottom: "2%" }}>
                                    <div className="line-grey">
                                    </div>
                                    <div><p style={{ color: "grey", margin: "0", fontSize: "20px" }}>or</p></div>
                                    <div className="line-grey">
                                    </div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div onClick={() => this.resendCode()}>
                                        <p style={{ color: "#2A5555", fontWeight: 500, cursor: "pointer", marginLeft: "5px" }}>Resend OTP</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="design-bottom">


                    </div> */}

                </div>

            </div >
        )
    }
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,

    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(ResetPassword));