import React, { Component } from "react";

import hermoso from "../assets/hermoso.png"
import { withRouter } from "../components/withRouter";
import { login, resetPassword } from "../Path/Path";
import { toast } from "react-toastify";
import { CircularProgress } from '@mui/material';
import jwt_decode from "jwt-decode";

import { connect } from "react-redux";
import store from "../store";
import action from "../store/action";
class NewPassword extends Component {
    state = {
        email: this.props.location?.state?.email,
        password: "",
        confirmPassword: "",
        loading: "",
    }
    changePasswordHandler = async () => {
        this.setState({ loading: true })
        if (this.state.password === "") {
            toast.warn("Enter New Password")

        }
        else if (this.state.confirmPassword === '') {
            toast.warn("Enter Confirm Password")
        }

        else {
            if (this.state.confirmPassword === this.state.password) {
                let params = {
                    password: this.state.password,
                }
                await resetPassword(this.state.email, params)
                    .then((res) => {
                        if (res?.success) {
                            this.props.history("/")
                        } else {
                            toast.warn(res?.message)
                        }
                        console.log(res, "ressssssssssssssss")

                    }).catch((err) => {
                        toast.warn(err.message)

                    })
            }
            else {
                toast.warn('Your password is not matched')
            }


        }
        // this.props.history("/")
        this.setState({ loading: false })
    }
    render() {

        return (
            <div>

                <div className="container-fluid p-0 background-pattern">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "2%", height: "450px" }}>
                            <div className='Login-center'>
                                <div style={{ width: "180px", height: "75px" }}>
                                    <img width="100%" height="100%" style={{ objectFit: "contain" }} src={hermoso}></img>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='login-main'>Reset Password</p>
                                </div>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>New Password</p>
                                </div>
                                <input value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className='register-input' placeholder='New Password'></input>
                                <div style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <p className='email'>Confirm Password</p>
                                </div>
                                <input type="password" value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })} className='register-input' placeholder='Re-enter password'></input>

                                <button onClick={() => this.changePasswordHandler()} loading={this.state.loading} className='signup-btn'>
                                    {
                                        this.state.loading ?
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <CircularProgress size={15} style={{ color: "white", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                            </div>

                                            :
                                            <p className='signup-text'>Reset</p>
                                    }
                                </button>


                            </div>
                        </div>
                    </div>

                    {/* <div className="design-bottom">


                    </div> */}

                </div>

            </div >
        )
    }
}
const mapStatetoProps = (state) => {
    return {
        user: state.user,

    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload)),

    }
}



export default connect(mapStatetoProps, mapDispatchtoProps)(withRouter(NewPassword));